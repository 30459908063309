import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

// eslint-disable-next-line
export const HTMLPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <div className="container content-container">
      <h2>{title}</h2>
      <PageContent className="content" content={content} />
    </div>
  )
}

HTMLPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const HTMLPage = ({ data, pageContext }) => {
  const { markdownRemark } = data
  const { title } = markdownRemark.frontmatter
  const { html } = markdownRemark

  return (
    <Layout pageContext={pageContext}>
      <HTMLPageTemplate
        title={title}
        contentComponent={HTMLContent}
        content={html}
      />
    </Layout>
  )
}

HTMLPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape(HTMLPageTemplate.propTypes).isRequired,
    }),
  }),
  pageContext: PropTypes.object.isRequired,
}

export default HTMLPage

export const htmlPageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
